// ==UserScript==
// @name Submit Form on Ctrl+Enter
// @description When in a textarea forms will be submitted on Ctrl+Enter.
// @include *
// @namespace https://userscripts.kevincox.ca
// @version  1
// @grant    none
// ==/UserScript==

document.addEventListener("keydown", e => {
	if (!e.ctrlKey) return;
	if (e.key != "Enter") return;
	if (e.defaultPrevented) return;

	let target = e.target as HTMLInputElement;
	if (!target.form) return;

	e.preventDefault();
	e.stopImmediatePropagation();
	target.form.requestSubmit();
})
